module.exports = {
    list: [
        "OAW-AP1201",
        "OAW-AP1201BG",
        "OAW-AP1231",
        "OAW-AP1232",
        "OAW-AP1311",
        "OAW-AP1321",
        "OAW-AP1322",
        "OAW-AP1331",
        "OAW-AP1351",
        "OAW-AP1361",
        "OAW-AP1361D",
        "OAW-AP1362",
        "OAW-AP1451",
        "OAW-AP1431",
        "OAW-AP1411",
        "OAW-AP1301H"
    ]
};
