<template>
    <div class="modal fade show" id="gatewayModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true" data-backdrop="static">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form id="gatewayForm" class="kt-form" novalidate="novalidate" @submit.prevent="onSubmit">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t("common_floorElevationLabel") }}</h5>
                        <button type="button" @click="cancelBleGatewayLocation" class="close" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group validated">
                            <label for="newGatewayForm_floorElevationInput">{{ $t("common_floorElevationLabel") }}</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="la la-upload"></i></span>
                                </div>
                                <span class="width80percent">
                                    <input v-model="vFloorElevation" type="text" class="form-control" id="newGatewayForm_floorElevationInput" :placeholder="$t('common_enterFloorElevation')" />
                                </span>
                            </div>
                            <div v-if="!isCorrectValue" class="invalid-feedback">
                                {{ $t("error_invalidDecimal") }}
                            </div>
                            <span class="form-text text-muted">{{ $t("gtw_bleGatewayFloorElevationExample") }}</span>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="bleGatewayModalCancelButton" type="button" class="btn btn-secondary" @click="cancelBleGatewayLocation" data-dismiss="modal">{{ $t("common_cancel") }}</button>
                        <button id="bleGatewaySubmitButton" type="button" :disabled="!isCorrectValue" @click="submitBleGatewayLocation" data-dismiss="modal" class="btn btn-brand trigger-submit">{{ $t("common_save") }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import commonVueHelper from '../../helpers/commonVueHelper';
import * as gatewaysUtil from "../map/js/gateways.js";

export default {
    data() {
        return {
            vFloorElevation: 2.5, // Default value in backend Gateway model
            isCorrectValue: true
        };
    },
    mounted: function() {
        // initialize touchspin buttons on elevation input field
        $("#newGatewayForm_floorElevationInput")
            .TouchSpin({
                buttondown_class: "btn btn-secondary",
                buttonup_class: "btn btn-secondary",
                verticalbuttons: true,
                verticalup: '<i class="la la-angle-up"></i>',
                verticaldown: '<i class="la la-angle-down"></i>',
                step: 0.01,
                decimals: 2
            })
            .on("change", () => {
                this.vFloorElevation = $("#newGatewayForm_floorElevationInput").val();
            });
    },
    watch: {
        vFloorElevation() {
            const regexp = /^\d+(\.\d{1,2})?$/;
            const value = this.vFloorElevation.toString();
            this.isCorrectValue = regexp.test(value);
        },
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        currentBLEGatewayLocation: function(gateway) {
            console.log("Component(GatewayModal)::watcher(currentBLEGatewayLocation) - called", gateway);
            if (gateway) {
                if (typeof gateway.floorElevation === "number") {
                    this.vFloorElevation = gateway.floorElevation;
                } else {
                    this.vFloorElevation = 2.5;
                }
                if (gateway.state === "SET_FLOOR_ELEVATION") {
                    // Verify if position is right
                    const locationRules = gatewaysUtil.getLocationRulesOfGateway(this.siteBLEGateways, gateway, gateway.location.coordinates);
                    if (locationRules.locationLess10m) {
                        // Show modal for incorrect position (< 10m)
                        $("#gatewayPositionIncorrectModal_textLess10m").show();
                        $("#gatewayPositionIncorrectModal_textMore15m").hide();
                        $("#gatewayPositionIncorrectModal").modal("show");
                    } else if (locationRules.locationMore15m) {
                        // Show modal for incorrect position (> 15m)
                        $("#gatewayPositionIncorrectModal_textLess10m").hide();
                        $("#gatewayPositionIncorrectModal_textMore15m").show();
                        $("#gatewayPositionIncorrectModal").modal("show");
                    } else {
                        // Show the gatewayModal vue
                        $("#gatewayModal").modal("show");
                    }
                }
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentBLEGatewayLocation", "getSelectedBuildingId", "getSelectedFloorId", "siteBLEGateways"])
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    methods: {
        ...mapActions(["setGatewayLocation", "setCurrentGatewayLocation", "storeLastAddedObjectLocation"]),
        submitBleGatewayLocation() {
            console.log("Component(gatewayModal)::cancelBleGatewayLocation() - called");
            let gwtLocationData = this.currentBLEGatewayLocation;
            gwtLocationData.floorElevation = Number(this.vFloorElevation);
            gwtLocationData.siteId = this.$route.params.siteId;
            gwtLocationData.buildingId = this.getSelectedBuildingId;
            gwtLocationData.floorId = this.getSelectedFloorId;
            this.setGatewayLocation(gwtLocationData);

            // Store the last added point in store
            this.storeLastAddedObjectLocation({
                type: "GATEWAY",
                id: gwtLocationData.id,
                lastAddedObjectLocation: gwtLocationData.location
            });

            $("#gatewayModal").modal("hide");
        },
        cancelBleGatewayLocation() {
            console.log("Component(gatewayModal)::submitBleGatewayLocation() - called");
            this.setCurrentGatewayLocation({ id: "", bleMacAddress: "", floorElevation: 2.5, state: "" });
            // Initialisation of tooltips
            commonVueHelper.initToolTips();
        }
    }
};
</script>

<style scoped>
.width80percent {
    width: 90%;
}
</style>
