<template>
    <div
        class="modal fade show"
        id="importGatewaysFromOVModal"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-modal="true"
        style="display: none"
    >
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("common_importFromOVFile") }}</h5>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <div class="kt-section kt-section--first">
                        <div class="kt-section__body">
                            <div class="form-group marginBM20">
                                <label>{{ $t("common_fileBrowser") }}</label>
                                <div class="custom-file">
                                    <input
                                        type="file"
                                        @change="handleFileSelected"
                                        ref="csvFile"
                                        :lang="this.$i18n.locale"
                                        class="custom-file-input cursorPointer"
                                        accept=".csv"
                                        id="importGatewaysFromOVInputFile"
                                    />
                                    <label
                                        class="custom-file-label"
                                        id="importGatewaysFromOVInputLabel"
                                        for="customFile"
                                    >{{ $t("common_chooseFile") }}</label
                                    >
                                    <span class="form-text text-muted">
                                        <a
                                            class="kt-link"
                                            id="learnHowToDownloadCSVFromOVLink"
                                            href="#"
                                            data-placement="bottom"
                                            data-toggle="kt-popover"
                                            data-html="true"
                                            title
                                            data-content="<img class='width100P' src='/assets/ale/ov_export_aps.png' />"
                                            :data-original-title="$t('gtw_importFromOVPopoverTitle')"
                                        >{{ $t("gtw_learnHowToDownload") }}</a
                                        >
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="isCSVFileIsEmpty" class="kt-font-danger marginTMinus15">
                        {{ $t("common_fileIsEmpty") }}
                    </div>
                    <div v-if="isSheetNotFound" class="kt-font-danger marginTMinus15">
                        {{ $t("gtw_gatewaysSheetNotFound") }}
                    </div>
                    <div
                        v-show="errorDisplayed"
                        class="kt-section kt-margin-t-15 kt-margin-b-0"
                    >
                        <div class="kt-font-danger kt-margin-t-15 kt-margin-b-15">
                            {{ $t("common_invalidXlsFile") }}
                        </div>
                        <div class="kt-scroll" data-scroll="true" data-height="160">
                            <div class="kt-section__content">
                                <table class="table kt-font-danger table-sm">
                                    <thead>
                                        <tr>
                                            <th class="kt-align-center">
                                                {{ $t("common_rowNumber") }}
                                            </th>
                                            <th class="kt-align-center">{{ $t("error_message") }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(errorObj, i) in this.csvErrors" :key="i">
                                            <td
                                                v-if="errorObj.row"
                                                class="kt-align-center font12"
                                                scope="row"
                                            >
                                                {{ errorObj.row + 1 }}
                                            </td>
                                            <td v-else class="kt-align-center font12" scope="row">
                                                {{ $t("common_many") }}
                                            </td>

                                            <td
                                                v-if="errorObj.error == 'required'"
                                                class="kt-align-center font12"
                                            >
                                                {{
                                                    $t("common_fieldIsRequired", {
                                                        value: errorObj.column
                                                    })
                                                }}
                                            </td>
                                            <td v-else class="kt-align-center font12">
                                                {{ $t(errorObj.error, { value: errorObj.value }) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                        id="importGatewaysModalImportButton"
                        type="button"
                        @click="importGateways"
                        v-bind:disabled="
                            isImportButtonDisabled || localIsUploadGatewaysInProgress
                        "
                        :class="{
                            'btn btn-brand kt-margin-r-5 kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': localIsUploadGatewaysInProgress,
                            'btn btn-brand kt-margin-r-5': !localIsUploadGatewaysInProgress
                        }"
                    >
                        {{ $t("common_import") }}
                    </button>
                    <button
                        id="importGatewaysModalCancelButton"
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                    >
                        {{ $t("common_cancel") }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Papa from "papaparse";
const regexUtil = require("../../constants/regex");

export default {
    data() {
        return {
            errorDisplayed: false,
            csvData: null,
            isImportButtonDisabled: true,
            csvErrors: null,
            csvFile: null,
            isCSVFileIsEmpty: false,
            isSheetNotFound: false,
            localIsUploadGatewaysInProgress: false
        };
    },
    created: function() {
        console.log("Component(ImportGatewaysFromOVModal)::created() - called");
    },
    mounted: function() {
        console.log("Component(ImportGatewaysFromOVModal)::mounted() - called");
    },
    destroyed: function() {
        console.log("Component(ImportGatewaysFromOVModal)::destroyed() - called");
    },
    validations: {
    // -- validations -- List of controls to apply to validate a form.
    // --
    },
    watch: {
    // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
    // --
        isUploadGatewaysFromOVInProgress: function() {
            this.localIsUploadGatewaysInProgress = this.isUploadGatewaysInProgress;
        },
        showImportGatewaysFromOVModal() {
            if (this.showImportGatewaysFromOVModal) {
                console.log(
                    "Component(ImportGatewaysFromOVModal)::showImportGatewaysFromOVModal() - show modal"
                );
                $("#importGatewaysFromOVModal").modal("show");
            }
            this.resetShowImportGatewaysFromOVModal();
        },
        hideImportGatewaysFromOVModal() {
            if (this.hideImportGatewaysFromOVModal) {
                console.log(
                    "Component(ImportGatewaysFromOVModal)::resetHideImportGatewaysFromOVModal() - hide modal"
                );
                $("#importGatewaysFromOVModal").modal("hide");
            }
            this.resetHideImportGatewaysFromOVModal();
        }
    },
    computed: {
    // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
    // To compose new data from existing datasource
    // To reference a value from the template (cached value)
    // --
        ...mapGetters([
            "showImportGatewaysFromOVModal",
            "hideImportGatewaysFromOVModal",
            "isUploadGatewaysFromOVInProgress"
        ])
    },
    methods: {
        ...mapActions([
            "massImportGateways",
            "resetShowImportGatewaysFromOVModal",
            "resetHideImportGatewaysFromOVModal"
        ]),
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        handleFileSelected() {
            
            console.log("Component(ImportGatewaysFromOVModal)::handleFileSelected() - called");

            this.errorDisplayed = false;
            this.isCSVFileIsEmpty = false;
            this.isSheetNotFound = false;

            if (this.$refs.csvFile.files.length > 0) {
                
                this.csvFile = this.$refs.csvFile.files[0];

                Papa.parse(this.csvFile, {
                    header: true,
                    skipEmptyLines: true,
                    complete: results => {
                        
                        console.log("Finished:", results.data);

                        if (results.data && results.data.length == 0 && results.errors && results.errors.length == 0) {
                            this.isCSVFileIsEmpty = true;
                            return;
                        }

                        const arrayOfBleMacAddresses = _.map(results.data, "BLE MAC");
                        const arrayOfWifiMacAddresses = _.map(results.data, "AP MAC");
                        var allGateways = _.compact(
                            arrayOfBleMacAddresses.concat(arrayOfWifiMacAddresses)
                        );
                        var occurrences = allGateways.reduce(function(obj, item) {
                            obj[item] = (obj[item] || 0) + 1;
                            return obj;
                        }, {});

                        // Check manually for mac addresses unicity in entore file
                        for (let macAddr in occurrences) {
                            if (occurrences[macAddr] > 1) {
                                // Push an error
                                results.errors.push({
                                    error: "error_macAddressesMustHaveDifferentsValues",
                                    value: macAddr
                                });
                            }
                        }

                        // Check manually for mac addresses unicity in entore file
                        for (var i = 0; i < results.data.length; i++) {
                            if (!results.data[i]["AP Name"]) {
                                // Push an error
                                results.errors.push({
                                    error: "required",
                                    row: i + 1,
                                    column: "AP Name",
                                    value: null
                                });
                            }
                            if (!results.data[i]["BLE MAC"]) {
                                // Push an error
                                results.errors.push({
                                    error: "required",
                                    row: i + 1,
                                    column: "BLE MAC",
                                    value: null
                                });
                            }
                        }

                        if (results.errors.length > 0) {
                            this.errorDisplayed = true;
                            this.isImportButtonDisabled = true;
                            this.csvErrors = results.errors;
                        } else {
                            this.errorDisplayed = false;
                            this.isImportButtonDisabled = false;
                            this.csvData = results.data;
                        }
                    }
                });
            }
        },
        importGateways() {

            console.log("Component(ImportGatewaysFromOVModal)::importGateways() - called");

            let gateways = [];
            for (var currentLine of this.csvData) {

                let obj = {};
                if (currentLine.hasOwnProperty("AP Name")) {
                    obj.name = currentLine["AP Name"];
                }
                if (currentLine.hasOwnProperty("BLE MAC")) {
                    obj.bleMacAddress = currentLine["BLE MAC"];
                }
                if (currentLine.hasOwnProperty("AP MAC")) {
                    obj.wifiMacAddress = currentLine["AP MAC"];
                }
                if (currentLine.hasOwnProperty("AP Version")) {
                    obj.softwareVersion = currentLine["AP Version"];
                }
                if (currentLine.hasOwnProperty("Group Name")) {
                    obj.groupName = currentLine["Group Name"];
                }
                if (currentLine.hasOwnProperty("IP Address")) {
                    obj.ipAddress = currentLine["IP Address"];
                }
                gateways.push(obj);
            }
            let data = {
                siteId: this.$route.params.siteId,
                gateways: gateways
            };
            // Create the new site in the backend
            this.massImportGateways(data);
        }
    },
    components: {
    // -- Components -- List of local components used in the current template
    // --
    }
};
</script>

<style scoped>
.cursorPointer {
  cursor: pointer;
}
.marginTMinus15 {
  margin-top: -15px;
}
</style>
<style>
.font12 {
  font-size: 12px;
}
</style>
