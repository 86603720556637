<template>
    <div class="modal fade show" id="gatewayPositionIncorrectModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true" data-backdrop="static" style="display: none">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("error_GatewayLocationNotCorrectTitle") }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="onClickOkButton"></button>
                </div>
                <div class="modal-body">
                    <p id="gatewayPositionIncorrectModal_textLess10m" style="display: none">{{ $t("error_GatewayLocationNotCorrectTextLess10m") }}</p>
                    <p id="gatewayPositionIncorrectModal_textMore15m" style="display: none">{{ $t("error_GatewayLocationNotCorrectTextMore15m") }}</p>
                </div>
                <div class="modal-footer">
                    <button id="confirmLeaveYesButton" type="button" @click="onClickOkButton" data-dismiss="modal" class="btn btn-brand">{{ $t("common_okThanks") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters(['currentBLEGatewayLocation'])
    },
    methods: {
        onClickOkButton() {
            console.log("Component(gatewayPositionIncorrectModal)::hide() - called");
            if (this.currentBLEGatewayLocation.state === "SET_FLOOR_ELEVATION") {
                // It is new position of gateway show floor elevation modal
                $("#gatewayModal").modal("show");
            } else {
                // Hide only modal it is edit gateway location
                $("#gatewayPositionIncorrectModal").modal("hide");
            }
        }
    }
};
</script>
