<template>
    <div class="baseTemplate" id="gatewaysLocation">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="gatewaysLocation"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="gtw_bleGatewaysLocationSubTitle"></app-sitetitle>
                            <div class="kt-subheader__toolbar">
                                <div class="kt-subheader__wrapper">
                                    <div class="dropdown displayInlineBlock">
                                        <button
                                            class="btn btn-bold btn-brand btn-sm"
                                            type="button"
                                            @click="onClickNeedHelp"
                                        >
                                            {{ $t("common_needHelp") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <app-accesserrorportlet :siteId="siteId" activeItem="gatewaysLocation" ref="accessErrorPortlet" v-show="!accessGranted"></app-accesserrorportlet>

                        <div class="kt-content kt-grid__item kt-grid__item--fluid" v-if="accessGranted">
                            <!-- begin:: Content -->
                            <div class="row">
                                <div class="col-lg-4">
                                    <!--begin::Portlet-->
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("gtw_bleGatewaysLocation") }}
                                                </h3>
                                            </div>
                                            <div class="kt-portlet__head-toolbar">
                                                <div class="kt-portlet__head-toolbar">
                                                    <div class="kt-portlet__head-actions">
                                                        <a id="toggleGatewayFilterButton" v-if="siteBLEGateways && siteBLEGateways.length > 0 && currentBLEGatewayLocation.state !== 'START_MEASURE'" href="#" v-on:click="toggleFilter" :class="{ 'btn-brand': isFilterDisplayed }" class="btn btn-outline-brand btn-sm btn-icon btn-icon-md">
                                                            <i class="la la-filter"></i>
                                                        </a>
                                                    </div>
                                                    <template v-if="currentBLEGatewayLocation.state === 'START_MEASURE'">
                                                        <a id="cancelMeasureGatewaysButton" href="#" @click="onCancelMeasure" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('gtw_cancelAction')" class="marginL5 btn btn-sm btn-outline-dark btn-bold">
                                                            <span id="cancelMeasureGatewaysLabel">{{ $t("common_cancel") }}</span>
                                                        </a>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <!--begin emptyList component-->
                                            <app-emptylist imgName="empty_gateway.svg" mainTextKey="gtw_notFound" subTextKey="gtw_notFoundSubLabel" targetObjectName="GATEWAY" v-bind:isDisplayed="isEmptyList"></app-emptylist>
                                            <!--end emptyList component-->

                                            <span class="counterTopList kt-badge font500 kt-margin-b-25 kt-badge--info kt-badge--inline list-top-badge" v-if="siteBLEGateways && siteBLEGateways.length == 1">{{ siteBLEGateways.length }} {{ $t("gtw_gateway") }}</span>
                                            <span class="counterTopList kt-badge font500 kt-margin-b-25 kt-badge--info kt-badge--inline list-top-badge" v-else-if="siteBLEGateways && siteBLEGateways.length > 1">{{ siteBLEGateways.length }} {{ $t("gtw_gateways") }}</span>

                                            <div class="form-group" v-if="filteredGatewaysBySearch">
                                                <div v-if="isFilterDisplayed" id="gatewaysFilter">
                                                    <app-multiselect
                                                        v-model="filtersSelected"
                                                        v-on:close="updateScrollbar"
                                                        v-on:open="updateScrollbarFilterOpen"
                                                        :options="filtersOptions"
                                                        :multiple="true"
                                                        group-values="items"
                                                        group-label="type"
                                                        :group-select="true"
                                                        v-bind:placeholder="$t('gtw_filterBy')"
                                                        track-by="name"
                                                        label="name"
                                                        :selectLabel="$t('common_multiselectSelectLabel')"
                                                        :selectGroupLabel="$t('common_multiselectSelectGroupLabel')"
                                                        :selectedLabel="$t('common_multiselectSelectedLabel')"
                                                        :deselectLabel="$t('common_multiselectDeselectLabel')"
                                                        :deselectGroupLabel="$t('common_multiDeselectGroupLabel')"
                                                    >
                                                        <span slot="noResult">{{$t("common_multiselectNoFound")}}</span>
                                                    </app-multiselect>
                                                </div>
                                                <div v-if="siteBLEGateways && siteBLEGateways.length > 0" class="kt-input-icon kt-input-icon--left" style="margin-top: -10px; margin-bottom: -10px;">
                                                    <input :placeholder="$t('common_searchBy')" class="form-control" id="gatewayslocation_searchInput" type="text" v-model="searchTextValue" />
                                                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                                        <span><i class="la la-search"></i></span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="tab-content">
                                                <div class="tab-pane active">
                                                    <div class="kt-scroll" data-height="600" v-if="filteredGatewaysBySearch.length > 0" data-scroll="true" id="gatewayListWithScrollbar">
                                                        <div class="kt-widget4" id="gatewaysList">
                                                            <!-- Items will goes there -->
                                                            <div :key="gateway.id" class="kt-widget4__item gatewayLine" v-for="(gateway, index) in filteredGatewaysBySearch" :class="index === filteredGatewaysBySearch.length - 1 ? 'kt-margin-b-30' : ''">
                                                                <div :data-id="gateway.id" @click="onHighlight" class="kt-widget4__info" v-bind:class="getHighlightListClass(gateway)">
                                                                    <a :data-id="gateway.id" @click="onHighlight" class="highlightGatewayLocationLink kt-widget4__username" href="#" v-bind:class="getHighlightClass(gateway)">
                                                                        {{ gateway.name }}
                                                                    </a>
                                                                    <p class="kt-widget4__text colorSubText">{{ gateway.bleMacAddress }}</p>
                                                                    <p class="kt-widget4__text colorSubText" v-if="gateway.location && gateway.location.type">{{ gateway.building.name }} / {{ gateway.floor.name }} ({{ $t("b&f_level") }}: {{ gateway.floor.floorNumber }})</p>
                                                                    <p class="kt-widget4__text colorSubText" v-if="gateway.location && gateway.location.type">Lat, Long: {{ gateway.location.coordinates[1] }}, {{ gateway.location.coordinates[0] }}</p>
                                                                </div>

                                                                <a :id="'gateway_setLocation_' + gateway.bleMacAddress" :data-blemacaddress="gateway.bleMacAddress" :data-id="gateway.id" :data-original-title="$t('gtw_setGatewayLocationOnTheFloorMap')" @click="onSetLocation" class="setGatewayLocationBtn btn btn-sm btn-outline-brand btn-bold" data-placement="top" data-toggle="kt-tooltip" href="#" v-if="(!gateway.location || !gateway.location.type) && (currentBLEGatewayLocation.bleMacAddress === '' || currentBLEGatewayLocation.state === 'HIGHLIGHT') && currentBLEGatewayLocation.state !== 'START_MEASURE' && !currentSite.isInProduction">
                                                                    <i class="la la-map-marker notYetLocatedIcon"></i>
                                                                    {{ $t("common_setLocation") }}
                                                                </a>
                                                                <button v-if="(!gateway.location || !gateway.location.type) && (currentBLEGatewayLocation.bleMacAddress === '' || currentBLEGatewayLocation.state === 'HIGHLIGHT') && currentBLEGatewayLocation.state !== 'START_MEASURE' && currentSite.isInProduction" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_unauthorizedAction')" class="disabled setGatewayLocationBtn btn btn-sm btn-outline-brand btn-bold">
                                                                    <i class="la la-map-marker notYetLocatedIcon"></i>
                                                                    {{ $t("common_setLocation") }}
                                                                </button>

                                                                <a :id="'gateway_cancelLocation_' + gateway.bleMacAddress" :data-blemacaddress="gateway.bleMacAddress" :data-id="gateway.id" :data-original-title="$t('gtw_cancelAction')" @click="onCancelLocation" class="cancelGatewayLocationButton btn btn-sm btn-outline-dark btn-bold" data-placement="top" data-toggle="kt-tooltip" href="#" v-if="(!gateway.location || !gateway.location.type) && currentBLEGatewayLocation.bleMacAddress === gateway.bleMacAddress && !currentSite.isInProduction"> {{ $t("common_cancel") }} </a>
                                                                <button v-if="(!gateway.location || !gateway.location.type) && currentBLEGatewayLocation.bleMacAddress === gateway.bleMacAddress && currentSite.isInProduction" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_unauthorizedAction')" class="disabled cancelGatewayLocationButton btn btn-sm btn-outline-dark btn-bold">
                                                                    {{ $t("common_cancel") }}
                                                                </button>

                                                                <template v-if="gateway.location && gateway.location.type && currentBLEGatewayLocation.bleMacAddress === gateway.bleMacAddress && currentBLEGatewayLocation.state == 'EDIT'">
                                                                    <a :id="'gateway_cancelEditLocation_' + gateway.bleMacAddress" :data-id="gateway.id" :data-original-title="$t('gtw_cancelAction')" @click="onCancelEditLocation" class="cancelEditGatewayLocationButton alreadyLocatedLink btn btn-sm btn-outline-dark btn-bold" data-placement="top" data-toggle="kt-tooltip" href="#"> {{ $t("common_cancel") }} </a>
                                                                    <a :id="'gateway_saveEditLocation_' + gateway.bleMacAddress" :data-blemacaddress="gateway.bleMacAddress" :data-id="gateway.id" :data-original-title="$t('gtw_saveEditGatewayLocation')" @click="onSaveEditLocation" class="saveEditGatewayLocationButton btn btn-sm btn-outline-dark btn-bold" data-placement="top" data-toggle="kt-tooltip" href="#"> <i class="la la-save notYetLocatedIcon"></i> {{ $t("common_save") }} </a>
                                                                </template>

                                                                <template v-if="gateway.location && gateway.location.type && (currentBLEGatewayLocation.bleMacAddress === '' || currentBLEGatewayLocation.state === 'HIGHLIGHT') && (currentBLEGatewayLocation.state !== 'START_MEASURE' || currentBLEGatewayLocation.state !== 'CREATE')">
                                                                    <a :id="'gateway_highlight_' + gateway.bleMacAddress" :data-blemacaddress="gateway.bleMacAddress" :data-id="gateway.id" :data-original-title="$t('gtw_highlightGatewayLocation')" @click="onHighlight" class="highlightGatewayLocationButton alreadyLocatedLink btn btn-sm btn-outline-success btn-bold" data-placement="top" data-toggle="kt-tooltip" href="#">
                                                                        <i class="la la-crosshairs alreadyLocatedIcon"></i>
                                                                    </a>

                                                                    <div v-if="!currentSite.isInProduction" class="editGatewayDropdown btn-group dropleft show" data-toggle="kt-tooltip" :data-original-title="$t('gtw_editGatewayLocation')" data-placement="top">
                                                                        <a :id="'gateway_editDropdown_' + gateway.bleMacAddress" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" class="editGatewayDropdownButton gtwListLink btn btn-sm btn-outline-warning btn-bold">
                                                                            <i class="la la-pencil gtwListIcon"></i>
                                                                        </a>
                                                                        <div x-placement="left-start" class="dropdown-menu" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-184px, 0px, 0px);">
                                                                            <a :id="'gateway_editLocationItem_' + gateway.bleMacAddress" @click="onEditLocation" :data-blemacaddress="gateway.bleMacAddress" :data-id="gateway.id" href="#" class="editGatewayLocationItem dropdown-item">{{ $t("common_edit_location") }}</a>
                                                                            <a :id="'gateway_editFloorElevation_' + gateway.bleMacAddress" @click="onEditFloorElevation" :data-blemacaddress="gateway.bleMacAddress" :data-id="gateway.id" href="#" class="editGatewayFloorElevationItem dropdown-item">{{ $t("common_edit_floor_elevation") }}</a>
                                                                        </div>
                                                                    </div>

                                                                    <button v-else data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_unauthorizedAction')" class="disabled gtwListLink btn btn-sm btn-outline-warning btn-bold">
                                                                        <i class="la la-pencil gtwListIcon"></i>
                                                                    </button>

                                                                    <a v-if="!currentSite.isInProduction" :id="'gateway_remove_' + gateway.bleMacAddress" :data-blemacaddress="gateway.bleMacAddress" :data-id="gateway.id" :data-original-title="$t('gtw_removeGatewayLocation')" @click="onDeleteLocation" class="removeGatewayLocationButton btn btn-sm btn-outline-danger btn-bold" data-placement="top" data-toggle="kt-tooltip" href="#">
                                                                        <i class="la la-remove alreadyLocatedIcon"></i>
                                                                    </a>
                                                                    <button v-else data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_unauthorizedAction')" class="disabled removeGatewayLocationDisabledButton btn btn-sm btn-outline-danger btn-bold">
                                                                        <i class="la la-remove alreadyLocatedIcon"></i>
                                                                    </button>
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Portlet-->
                                </div>

                                <div class="col-lg-8">
                                    <!--begin::Portlet-->
                                    <div class="kt-portlet kt-portlet--tab">
                                        <div class="kt-portlet__body padding2">
                                            <div class="kt-section kt-margin-b-0">
                                                <app-locationmap mode="gatewaysLocation" ref="locationmap"></app-locationmap>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Portlet-->
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: modal -->
                    <app-gatewaymodal></app-gatewaymodal>
                    <app-gatewaypositionmodal></app-gatewaypositionmodal>
                    <app-gatewaypositionincorrectmodal></app-gatewaypositionincorrectmodal>
                    <app-importgatewaysfromXLSmodal ref="importGatewaysModal"></app-importgatewaysfromXLSmodal>
                    <app-importgatewaysfromOVmodal ref="importGatewaysFromOVModal"></app-importgatewaysfromOVmodal>
                    <!-- end:: modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import SiteTitle from "../site/widgets/sitetitle.vue";
import LocationMap from "../map/locationmap.vue";
import EmptyList from "./../utils/emptylist.vue";
import AccessErrorPortlet from "../utils/accesserrorportlet.vue";
import GatewayModal from "../modals/gatewaymodal.vue";
import GatewayPositionModal from "../modals/gatewaypositionmodal.vue";
import commonVueHelper from '../../helpers/commonVueHelper';
import GatewayIncorrectPositionModal from "../modals/gatewayincorrectpositionmodal.vue";
import ImportGatewaysModal from "../modals/importgatewaysmodal.vue";
import ImportGatewaysFromOVModal from "../modals/importgatewaysfromovmodal.vue";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            searchTextValue: "",
            isEmptyList: false,
            isMounted: false,
            localGateways: [],
            filtersSelected: [],
            filtersOptions: [],
            isFilterDisplayed: false
        };
    },
    created: function() {
        console.log("Component(GatewaysLocation)::created() - called");
        // Reset current autocalibration tag location to empty by default
        this.setCurrentGatewayLocation({ id: "", bleMacAddress: "", state: "" });
        
        this.getSiteIndoorAreas(this.siteId);
        this.getSiteWalls(this.siteId);
        this.getSiteBLEGateways(this.siteId);
        this.getSiteAutoCalibrationTags(this.siteId);
        this.getSiteGeofences(this.siteId);
    },
    mounted: function() {
        console.log("Component(GatewaysLocation)::mounted() - Init metronic layout");
        this.isMounted = true;
        $(window).on("resize", () => {
            this.updateScrollbar();
        });
        this.updateScrollbar();
        this.getWallTypes();
    },
    destroyed: function() {
        console.log("Component(GatewaysLocation)::destroyed() - called");
        // this.resetGatewaysState();
        this.setIndoorAreasLayerDisplayed(false);
        this.setGeofencesLayerDisplayed(false);
        this.setGatewaysLayerDisplayed(false);
        this.setAutocalibrationTagsLayerDisplayed(false);
        this.setWallsLayerDisplayed(false);
    },
    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        // --
        this.resetGatewaysState();
        next();
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        siteBLEGateways() {
            if (this.siteBLEGateways) {
                this.localGateways = this.siteBLEGateways;
                if (this.siteBLEGateways.length == 0) {
                    this.isEmptyList = true;
                } else {
                    this.isEmptyList = false;
                }

                // add keywords to filter multiselect element, if any
                if (this.allGatewaysKeywords.length !== 0) {
                    let keywordsItems = [];
                    for (let item in this.allGatewaysKeywords) {
                        if (this.allGatewaysKeywords.hasOwnProperty(item)){
                            keywordsItems.push({
                                type: "keyword",
                                name: this.allGatewaysKeywords[item]
                            });
                        }
                    }
                    this.updateFiltersOptionsElement("--- " + i18n.t("gtw_bleGatewayKeywordsSmallLabel"), keywordsItems);
                }

                // add filter by located / not located
                let filterItems = [
                    {
                        type: "location",
                        id: "notLocated",
                        name: i18n.t("gtw_notLocatedGateways")
                    },
                    {
                        type: "location",
                        id: "located",
                        name: i18n.t("gtw_locatedGateways")
                    }
                ];
                this.updateFiltersOptionsElement("--- " + i18n.t("gtw_gatewaysLocation"), filterItems);

                let showModal = true;
                if (this.siteBLEGateways.length > 0){
                    this.siteBLEGateways.forEach(gateway => {
                        if (gateway.floor) {
                            showModal = false;
                        }
                    });
                    if (showModal) {
                        $("#gatewayPositionModal").modal("show");
                    }
                }

                // Now show gateway highlight
                if (this.$route.params && this.$route.params.highlight) {
                    // Come from siteoverview page to see location of gateway
                    // Query hightlight value is gateway id
                    let gatewayObj = this.getGatewayById(this.$route.params.highlight);
                    if (gatewayObj && gatewayObj.id) {
                        commonVueHelper.initToolTips();
                        setTimeout(()=> {
                            this.setCurrentGatewayLocation({id: gatewayObj.id, bleMacAddress: gatewayObj.bleMacAddress, state: "HIGHLIGHT"})
                        }, 10);
                    }
                }
            }
        },

        buildingsAndTheirFloorsArray(buildingsArray) {
            this.getSiteBLEGateways(this.siteId);
            if (buildingsArray && buildingsArray.length !== 0) {
                let buildingsFilter = {
                    type: "--- " + i18n.t("b&f_buildings"),
                    items: []
                };
                let floorsFilter = {
                    type: "--- " + i18n.t("b&f_floors"),
                    items: []
                };
                let building = null;
                let floor = null;
                for (let i in buildingsArray) {
                    if (buildingsArray.hasOwnProperty(i)){
                        building = buildingsArray[i];
                        buildingsFilter.items.push({
                            type: "building",
                            name: building.name,
                            id: building.id
                        });
                        if (building.floors && building.floors.length !== 0) {
                            for (let j in building.floors) {
                                if (building.floors.hasOwnProperty(j)) {
                                    floor = building.floors[j];
                                    floorsFilter.items.push({
                                        type: "floor",
                                        name: floor.name,
                                        id: floor.id
                                    });
                                }
                            }
                        }
                    }
                }
                this.updateFiltersOptionsElement(buildingsFilter.type, buildingsFilter.items, true);
                this.updateFiltersOptionsElement(floorsFilter.type, floorsFilter.items, true);
            }
        },

        isFilterDisplayed() {
            this.updateScrollbar();
        },

        filtersSelected() {
            this.updateScrollbar();
        },

        currentBLEGatewayLocation() {
            // Scroll only if highlight comes from the click on a marker inside the map
            if (this.currentBLEGatewayLocation.state === "HIGHLIGHT" && this.currentBLEGatewayLocation.isHighlightFromMap) {
                let element = $(".highlightGatewayLocationLink[data-id='" + this.currentBLEGatewayLocation.id + "']");
                $("#gatewayListWithScrollbar").scrollTop(0);
                $("#gatewayListWithScrollbar").animate({scrollTop: element.offset().top - 350}, 600);
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["siteBLEGateways", "currentSite", "currentBLEGatewayLocation", "getGatewayById", "buildingsAndTheirFloorsArray", "allGatewaysKeywords"]),

        // Computed property used to filter
        filteredGatewaysBySearch: function() {
            // Init tooltips when render will be finished
            this.$nextTick(() => {
                this.updateScrollbar();
                $('[data-toggle="kt-tooltip"]')
                    .tooltip({ trigger: "hover" })
                    .on("click", function() {
                        $(this).tooltip("hide");
                    });
            });
            let self = this;
            let filteredGateways = null;

            // 1- Apply filter according to search bar content
            if (!self.searchTextValue) {
                filteredGateways = this.localGateways;
            } else {
                filteredGateways = this.localGateways.filter(function(gateway) {
                    return gateway.bleMacAddress.toLowerCase().indexOf(self.searchTextValue.toLowerCase()) != -1 || gateway.name.toLowerCase().indexOf(self.searchTextValue.toLowerCase()) != -1 || (gateway.building && gateway.building.name.toLowerCase().indexOf(self.searchTextValue.toLowerCase()) != -1) || (gateway.floor && gateway.floor.name.toLowerCase().indexOf(self.searchTextValue.toLowerCase()) != -1);
                });
            }

            // 2- Apply filters as selected in filters list
            if (this.filtersSelected && this.filtersSelected.length !== 0) {
                // Some filters were positionned, let's filter gateways accordingly

                // Flatten filtersSelected array
                let flattenedFilters = {};
                let filter = null;
                for (let item in this.filtersSelected) {
                    if (this.filtersSelected.hasOwnProperty(item)){
                        filter = this.filtersSelected[item];
                        if (!flattenedFilters[filter.type]) {
                            flattenedFilters[filter.type] = [];
                        }
                        switch (filter.type) {
                            case "building":
                            case "floor":
                            case "location":
                                flattenedFilters[filter.type].push(filter.id);
                                break;
                            case "keyword":
                                flattenedFilters[filter.type].push(filter.name);
                                break;
                        }
                    }
                }
                // apply location filter
                if (flattenedFilters.location) {
                    let displayLocated = _.includes(flattenedFilters.location, "located");
                    let displayNotLocated = _.includes(flattenedFilters.location, "notLocated");
                    let tmpArray = filteredGateways.filter(function(gateway) {
                        if (gateway.location && gateway.location.type && displayLocated) return true;
                        else if ((!gateway.location || !gateway.location.type) && displayNotLocated) return true;
                        else return false;
                    });
                    filteredGateways = tmpArray;
                }
                // apply buildings filter
                if (flattenedFilters.building) {
                    let tmpArray = filteredGateways.filter(function(gateway) {
                        return gateway.building != null && _.includes(flattenedFilters.building, gateway.building.id);
                    });
                    filteredGateways = tmpArray;
                }
                // apply floors filter
                if (flattenedFilters.floor) {
                    let tmpArray = filteredGateways.filter(function(gateway) {
                        return gateway.floor != null && _.includes(flattenedFilters.floor, gateway.floor.id);
                    });
                    filteredGateways = tmpArray;
                }
                // apply keywords filter
                if (flattenedFilters.keyword) {
                    let tmpArray = filteredGateways.filter(function(gateway) {
                        if (gateway.keywords === null) return false;
                        for (let keyword in gateway.keywords.list) {
                            if (_.includes(flattenedFilters.keyword, gateway.keywords.list[keyword])) {
                                return true;
                            }
                        }
                        return false;
                    });
                    filteredGateways = tmpArray;
                }
            }
            return filteredGateways;
        },

        accessGranted: function() {
            if (this.isMounted) {
                return this.$refs.accessErrorPortlet.isAccessGranted();
            } else return true; // Do not display error portlet by default
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "getSiteBLEGateways",
            "setCurrentGatewayLocation",
            "resetGatewaysState",
            "setIndoorAreasLayerDisplayed",
            "setGeofencesLayerDisplayed",
            "setGatewaysLayerDisplayed",
            "setAutocalibrationTagsLayerDisplayed",
            "setWallsLayerDisplayed",
            "getSiteIndoorAreas",
            "getSiteWalls",
            "getSiteBLEGateways",
            "getWallTypes",
            "getSiteAutoCalibrationTags",
            "getSiteGeofences"

        ]),

        // This method is called to get list of classes that should be defined to highlight element into the list
        getHighlightClass(gateway) {
            return {
                cursorDefault: !gateway.location || !gateway.location.type,
                highlightedName: this.currentBLEGatewayLocation.id === gateway.id,
                colorMainText: this.currentBLEGatewayLocation.id !== gateway.id
            };
        },

        // This method is called to get list of classes that should be defined to highlight element into the list
        getHighlightListClass(gateway) {
            return {
                cursorPointer: gateway.location && gateway.location.type,
                highlightedItemList: this.currentBLEGatewayLocation.id === gateway.id
            };
        },

        // This method is called when user click ton "Set location" button
        onSetLocation(event) {
            let target = event.currentTarget;
            let id = $(target).attr("data-id");
            let bleMacAddress = $(target).attr("data-blemacaddress");
            this.setCurrentGatewayLocation({ id: id, bleMacAddress: bleMacAddress, floorElevation: this.getGatewayFloorElevation(id), state: "CREATE" });
        },

        onCancelMeasure() {
            console.log("Component(GatewaysLocation)::onCancelMeasure - Cancel measure button clicked.");
            if (this.currentBLEGatewayLocation.state === "START_MEASURE") {
                this.setCurrentGatewayLocation({ id: "", state: "CANCEL_MEASURE" });
            }
            // Initialisation of tooltips
            commonVueHelper.initToolTips();
        },

        onClickNeedHelp() {
            $("#gatewayPositionModal").modal("show");
        },

        // This method is called when user click ton "Highlight" button
        onHighlight(event) {
            let target = event.currentTarget;
            let id = $(target).attr("data-id");
            let bleMacAddress = $(target).attr("data-blemacaddress");
            // Get gateway by id
            let gatewayObj = this.getGatewayById(id);
            if (gatewayObj.location && gatewayObj.location.type) {
                this.setCurrentGatewayLocation({ id: id, bleMacAddress: bleMacAddress, state: "HIGHLIGHT" });
            }
        },

        // This method is called when user click ton "Edit location" button
        onEditLocation(event) {
            let target = event.currentTarget;
            let bleMacAddress = $(target).attr("data-blemacaddress");
            let id = $(target).attr("data-id");
            this.setCurrentGatewayLocation({ id: id, bleMacAddress: bleMacAddress, floorElevation: this.getGatewayFloorElevation(id), state: "EDIT" });
        },

        // This method is called when user click ton "Edit location" button
        onEditFloorElevation(event) {
            let target = event.currentTarget;
            let bleMacAddress = $(target).attr("data-blemacaddress");
            let id = $(target).attr("data-id");
            let gateway = this.getGatewayById(id);
            this.setCurrentGatewayLocation({ id: id, floorElevation: Number(gateway.floorElevation), location: gateway.location, bleMacAddress: bleMacAddress, state: "SET_FLOOR_ELEVATION" });
        },

        // This method is called when user click ton "Cancel" button after edit location of one gateway
        onCancelEditLocation(event) {
            let target = event.currentTarget;
            let bleMacAddress = $(target).attr("data-blemacaddress");
            let id = $(target).attr("data-id");
            this.setCurrentGatewayLocation({ id: id, bleMacAddress: bleMacAddress, floorElevation: this.getGatewayFloorElevation(id), state: "CANCEL_EDIT" });
            // Initialisation of tooltips
            commonVueHelper.initToolTips();
        },

        // This method is called when user click ton "Save" button after edit location of one gateway
        onSaveEditLocation(event) {
            let target = event.currentTarget;
            let bleMacAddress = $(target).attr("data-blemacaddress");
            let id = $(target).attr("data-id");
            this.setCurrentGatewayLocation({ id: id, bleMacAddress: bleMacAddress, state: "SAVE_EDIT" });
        },

        // This method is called when user click ton "delete" button
        onDeleteLocation(event) {
            let target = event.currentTarget;
            let bleMacAddress = $(target).attr("data-blemacaddress");
            let id = $(target).attr("data-id");
            this.setCurrentGatewayLocation({ id: id, bleMacAddress: bleMacAddress, floorElevation: 2.5, state: "DELETE" });
        },

        // This method is called when user click ton "Cancel" button
        onCancelLocation() {
            this.setCurrentGatewayLocation({ id: "", bleMacAddress: "", state: "CANCEL" });
            // Initialisation of tooltips
            commonVueHelper.initToolTips();
        },

        toggleFilter() {
            this.isFilterDisplayed = !this.isFilterDisplayed;
        },

        getGatewayFloorElevation(id) {
            let floorElevation = 2.5; // Default value in backend Gateway model
            if (id) {
                let gatewayObj = this.getGatewayById(id);
                if (gatewayObj) {
                    floorElevation = Number(gatewayObj.floorElevation);
                }
            }
            return floorElevation;
        },

        updateScrollbar() {
            if (this.siteBLEGateways && this.siteBLEGateways.length > 0) {
                let gatewayListScrollbar = $("#gatewayListWithScrollbar");
                if (gatewayListScrollbar) {
                    // timeout is used to be sure to get the current map height
                    setTimeout(() => {
                        let offset = this.isFilterDisplayed ? 190 + $("#gatewaysFilter").height() : 185;

                        let mapHeight = $("#map").height();
                        gatewayListScrollbar.data("height", mapHeight > offset * 2 ? mapHeight - offset : offset);
                        gatewayListScrollbar.attr("data-height", $("#map").height() - offset);
                        KTApp.init();
                        KTLayout.init();
                    }, 100);
                }
            }
        },

        // Allows to manage scrollbar height depending on dynamical vue-multiselect component
        updateScrollbarFilterOpen() {
            let gatewayListScrollbar = $("#gatewayListWithScrollbar");
            if (gatewayListScrollbar) {
                let offset = this.filtersSelected.length > 0 ? 230 + $("#gatewaysFilter").height() : 185 + $("#gatewaysFilter").height();

                let mapHeight = $("#map").height();
                gatewayListScrollbar.data("height", mapHeight > offset * 2 ? mapHeight - offset : offset);
                gatewayListScrollbar.attr("data-height", $("#map").height() - offset);
                KTApp.init();
                KTLayout.init();
            }
        },

        updateFiltersOptionsElement(filterType, filterItems, toPush) {
            const filterTypeFound = this.filtersOptions.find(element => element.type === filterType);
            if (filterTypeFound) {
                // Replace element {filterType: xxx} by {filterType: filterItems}
                this.filtersOptions = _.map(this.filtersOptions, element => {
                    if (element.type === filterType) {
                        element.items = filterItems;
                    }
                    return element;
                });
            } else {
                if (toPush) {
                    // Add element to the end of the filtersOptions array
                    this.filtersOptions.push({
                        type: filterType,
                        items: filterItems
                    });
                } else {
                    // Add element to the beginning of the filtersOptions array
                    this.filtersOptions.unshift({
                        type: filterType,
                        items: filterItems
                    });
                }
            }
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle,
        "app-locationmap": LocationMap,
        "app-emptylist": EmptyList,
        "app-accesserrorportlet": AccessErrorPortlet,
        "app-gatewaymodal": GatewayModal,
        "app-gatewaypositionmodal": GatewayPositionModal,
        "app-gatewaypositionincorrectmodal": GatewayIncorrectPositionModal,
        "app-importgatewaysfromXLSmodal": ImportGatewaysModal,
        "app-importgatewaysfromOVmodal": ImportGatewaysFromOVModal
    }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.baseTemplate {
    display: contents;
}
.padding2 {
    padding: 2px;
    z-index: 1;
}
.alreadyLocatedIcon {
    margin-top: -2px;
    margin-right: -11px;
    margin-left: -6px;
}
.alreadyLocatedLink {
    margin-right: 3px;
}
.notYetLocatedIcon {
    margin-top: -4px;
}
.colorMainText {
    color: #5c7cf7 !important;
}
.colorSubText {
    color: #5a6077 !important;
}
.cursorPointer {
    cursor: pointer;
}
.cursorDefault {
    cursor: default;
}
.list-top-badge {
    margin-top: -10px;
}
.font500 {
    font-weight: 500;
}
.counterTopList {
    font-size: 12px;
    padding: 10px 10px 10px 10px;
    width: fit-content;
}
.gatewayLine a:last-child {
    margin-right: 8px;
}

.gtwListIcon {
    margin-top: -2px;
    margin-right: -11px;
    margin-left: -6px;
}
.gtwListLink {
    margin-right: 3px;
}
.alreadyLocatedLink {
    margin-right: 3px;
}

#gatewaysFilter {
    margin-top: -15px;
    margin-bottom: 20px;
}

#gatewaysFilter >>> .multiselect__input {
    padding-top: 9px;
    font-size: 13px;
    font-weight: 400;
}
#gatewaysFilter >>> .multiselect__input::-moz-placeholder {
    color: #a7abc3;
    opacity: 1;
}
#gatewaysFilter >>> .multiselect__input:-ms-input-placeholder {
    color: #a7abc3;
}
#gatewaysFilter >>> .multiselect__input::-webkit-input-placeholder {
    color: #a7abc3;
}
#gatewaysFilter >>> .multiselect__placeholder {
    padding-top: 9px;
    font-size: 13px;
    font-weight: 400;
    color: #a7abc3;
}
#gatewaysFilter >>> .multiselect__tags {
    padding-top: 0px;
    border: 1px solid #ebedf2;
}
#gatewaysFilter >>> .multiselect__tag {
    margin-top: 7px;
    margin-bottom: 0px;
    background: transparent !important;
    border: 1px solid #5d78ff !important;
    color: #5d78ff !important;
    border-radius: 0px !important;
}
#gatewaysFilter >>> .multiselect__tag-icon:focus,
#gatewaysFilter >>> .multiselect__tag-icon:hover {
    background: inherit;
}
#gatewaysFilter >>> .multiselect__tag-icon:after {
    color: #a7abc3;
    font-size: 20px;
    font-weight: 300;
}

#gatewaysFilter >>> .multiselect__option--group,
#gatewaysFilter >>> .multiselect__option--group:after {
    font-weight: 0.7;
}
#gatewaysFilter >>> .multiselect__option--group.multiselect__option--highlight,
#gatewaysFilter >>> .multiselect__option--group.multiselect__option--highlight:after {
    background: #5578eb;
}

#gatewaysFilter >>> .multiselect__option,
#gatewaysFilter >>> .multiselect__option:after {
    padding: 4px;
    min-height: 25px;
    line-height: 17px;
    font-size: 14px;
}
#gatewaysFilter >>> .multiselect__option--highlight,
#gatewaysFilter >>> .multiselect__option--highlight:after {
    background: #6f8be7;
}
#gatewaysFilter >>> .multiselect__option--selected {
    font-weight: 500;
}
.removeGatewayLocationDisabledButton {
    margin-right: 8px;
}
</style>
